<template>
 <div class="bg-white shadow overflow-hidden sm:rounded-lg">
  <div class="px-4 py-5 sm:px-6">
   <h3 class="text-lg leading-6 font-medium text-gray-900">
    {{ $t("fileInformation") }}
   </h3>
   <p class="mt-1 max-w-2xl text-sm text-gray-500">{{ $t("fileInformationDetail") }}.</p>
  </div>
  <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
   <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
    <div class="sm:col-span-1">
     <dt class="text-sm font-medium text-gray-500">
      {{ $t("fileName") }}
     </dt>
     <dd class="mt-1 text-sm text-gray-900">
      {{ data.name }} <span class="text-xs text-gray-500"> ({{ data.id }})</span>
     </dd>
    </div>
    <div class="sm:col-span-1">
     <dt class="text-sm font-medium text-gray-500">
      {{ $t("mandateIdentification") }}
     </dt>
     <dd class="mt-1 text-sm text-gray-900">
      {{ data.mandateIdentification }}
     </dd>
    </div>

    <div class="sm:col-span-1" v-if="data.fileable">
     <dt class="text-sm font-medium text-gray-500">
      {{ $t("customerName") }}
     </dt>
     <dd class="mt-1 text-sm text-gray-900">
      {{ data.fileable.name }}
     </dd>
    </div>
    <div class="sm:col-span-1">
     <dt class="text-sm font-medium text-gray-500">
      {{ $t("customerAccount") }}
     </dt>
     <dd class="mt-1 text-sm text-gray-900" v-if="data.fileable">
      {{ data.fileable.customerAccount }}
     </dd>
    </div>
    <div class="sm:col-span-2">
     <dt class="text-sm font-medium text-gray-500">
      {{ $t("comment") }}
     </dt>
     <dd class="mt-1 text-sm text-gray-900">
      <span v-html="data.comment"></span>
     </dd>
    </div>

    <div
     class="sm:col-span-2"
     v-if="data.information_collections && data.information_collections.length > 0"
    >
     <dt class="text-sm font-medium text-gray-500">
      {{ $t("collectionsList") }}
     </dt>
     <dd class="mt-1 text-sm text-gray-900">
      <ul :key="key" role="list" class="border border-gray-200 rounded-md divide-y divide-gray-200">
       <li
        :class="[
         collect.status !== 'submitted' && collect.status !== 'ready' && collect.status !== 'closed'
          ? 'cursor-pointer'
          : '',
         'pl-3 pr-4 py-3 flex items-center justify-between text-sm',
        ]"
        v-for="collect in data.information_collections"
        :key="collect.id"
        @click="
         collect.status !== 'submitted' && collect.status !== 'ready' && collect.status !== 'closed'
          ? goToDetail(collect.content, collect.id, collect.status, collect.customer_file_id)
          : 'javascript:void(0)'
        "
       >
        <div class="w-0 flex-1 flex items-center">
         <span class="ml-2 flex-1 w-0 truncate">
          {{ data.name }}
         </span>
        </div>
        <div class="w-0 flex-1 items-center">
         <div class="inline-flex text-xs">
          <div class="items-center mr-2">
           <p
            :class="[
             collect.status === 'open'
              ? 'bg-blue-100 text-blue-800'
              : collect.status === 'submitted'
              ? 'bg-green-100 text-green-800'
              : 'bg-red-100 text-red-800',
             'px-2 inline-flex text-xs font-semibold rounded-full',
            ]"
           >
            {{ $t(getStatus(collect.status)) }}
           </p>
          </div>
          <div v-if="collect.locked_id">
           <span class="font-medium text-gray-900 mr-1">
            {{ $t("collectLockedDescription") }}
           </span>
          </div>
          <div v-else-if="collect.status === 'submitted'">
           <span class="font-medium text-gray-900 mr-1">
            {{ $t("collectSubmittedAt", { date: $d(collect.updated_at, "longNumb") }) }}
           </span>
          </div>
         </div>
        </div>
        <div class="w-1/4">
         <ProgressBar :percentage="this.getProgress(collect.content)" />
        </div>
        <div class="ml-1">
         <button
          @mouseenter="isHover = true"
          @mouseleave="isHover = false"
          type="button"
          @click.stop="this.$emit('refreshData')"
          :title="$t('refresh')"
          class="inline-flex items-center rounded-lg border border-transparent bg-blue-600 p-1 text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
         >
          <font-awesome-icon
           icon="arrows-rotate"
           class="flex-shrink-0 h-3 w-3"
           :class="{ 'fa-spin': isHover }"
          />
         </button>
        </div>
       </li>
      </ul>
     </dd>
    </div>
   </dl>
  </div>
 </div>
</template>

<script>
import ProgressBar from "../ProgressBar.vue";
import IntroductionCollect from "./IntroductionCollect.vue";
import { UserCircleIcon } from "@heroicons/vue/solid";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
 props: ["data", "customerFileId"],
 components: {
  UserCircleIcon,
  Loading,
  ProgressBar,
  IntroductionCollect,
 },
 data() {
  return {
   key: true,
   isLoading: false,
   isHover: false,
   fullPage: false,
   userInfos: {},
   showIntroduction: false,
   collectData: [{ customer_file_id: null, id: null }],
  };
 },
 methods: {
  emitActive() {},
  getProgress(data) {
   let completedCount = 0;
   let completedPercentage = 0;
   if (data) {
    let objData = JSON.parse(data);
    let keys = Object.keys(objData);
    keys.forEach((res) => {
     (res !== "callQueue" || res !== "studio" || res !== "svi") &&
     (objData[res].length > 0 || Object.keys(objData[res]).length > 0)
      ? (completedCount = completedCount + 1)
      : (completedCount = completedCount);
    });
   }

   completedPercentage = (completedCount / 7) * 100;
   if (completedPercentage > 100) completedPercentage = 100;
   return completedPercentage.toFixed(0);
  },
  getStatus(status) {
   return status === "open"
    ? "collectOpen"
    : status === "submitted"
    ? "submitted"
    : status === "active"
    ? "active"
    : status === "ready"
    ? "readyToBeDeployed"
    : status === "complete"
    ? "completed"
    : "unknown";
  },
  goToDetail(content, id, status, customer_file_id) {
   let routeData = undefined;
   if (id) {
    routeData = this.$router.resolve({
     path: `/customer-files/collect/${customer_file_id}/${id}/0`,
    });
   } else {
    routeData = this.$router.resolve({
     path: `/customer-files/collect/${customer_file_id}/new/0`,
    });
   }
   this.$router.push(routeData.path);
   // window.open(routeData.href, "_blank");
   // } else {
   //   this.$emit("activeBanner");
   //   this.$emit("success", false);
   //   this.$emit("successMsg", "This collection is closed.");
   // }
   // else if (status === "active") {
   //   this.$emit("activeBanner");
   //   this.$emit("success", false);
   //   this.$emit("successMsg", "Someone is working on this collection.");
   // }
  },
  formatDate(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear() +
    " at " +
    dateCreated.getHours() +
    ":" +
    ("0" + dateCreated.getMinutes()).slice(-2);

   return formatedDate;
  },
 },
 mounted() {},
 computed: {},
};
</script>

<style></style>
