<template>
  <div class="mt-0 flex">
    <div class="relative flex-grow focus-within:z-10 sm:w-auto">
      <div
        :class="[
          !onFocus
            ? 'focus:outline-none rounded-l absolute encom_pink_bg inset-y-0 p-2 left-0 flex items-center pointer-events-none'
            : 'rounded-l absolute encom_pink_bg inset-y-0 p-2 left-0 flex items-center pointer-events-none',
        ]"
      >
        <SearchIcon class="h-5 w-5 text-white" aria-hidden="true" />
      </div>
      <input
        type="search"
        name="search"
        id="search"
        class="rounded border search block w-full pl-11 sm:text-sm"
        @input="$emit('update:search', $event.target.value)"
        :placeholder="[!onFocus ? $t('header.search') : inputPlaceholder]"
        v-model="search"
        @focus="(onFocus = true), $emit('on-focus', true)"
        @blur="(onFocus = false), $emit('on-blur', false)"
      />
    </div>
  </div>
</template>

<script>
import { SortAscendingIcon, UsersIcon, SearchIcon } from "@heroicons/vue/solid";

export default {
  name: "SearchFilter",
  props: ["inputPlaceholder"],
  components: {
    SortAscendingIcon,
    UsersIcon,
    SearchIcon,
  },
  data() {
    return {
      onFocus: false,
      search: "",
    };
  },
};
</script>

<style scoped>
@media (min-width: 1024px) {
  .search {
    width: 70%;
    border-color: #e34b5f;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
  }
  .search:focus {
    width: 105%;
    background-color: #fff;
    border-color: #e34b5f;
    --tw-ring-color: #e34b5f;
  }
}
@media (max-width: 768px) {
  input {
    height: 30px;
  }

  input,
  input::-webkit-input-placeholder {
    font-size: 13px;
  }
}
.search {
  width: 100%;
  border-color: #e34b5f;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.search:focus {
  background-color: #fff;
  border-color: #e34b5f;
  --tw-ring-color: #e34b5f;
}
</style>
