<template>
 <div>
  <div class="bg-white sm:rounded-lg sm:p-6 m-5 shadow">
   <div class="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
    <h2 class="text-3xl font-extrabold text-gray-700 sm:text-4xl">
     <span class="block">Collecte 3CX</span>
    </h2>
    <p v-if="collectType === 'newSite'" class="mt-4 text-xl leading-6 text-gray-600">
     Ces informations nous permettent de paramétrer votre nouveau site sur 3CX.
    </p>
    <p v-else class="mt-4 text-xl leading-6 text-gray-600">
     Ces informations nous permettent de paramétrer votre 3CX.
    </p>
    <br />
    <p class="text-justify text-lg leading-6 text-gray-600">
     En prenant le temps de compléter avec soin et précision ce document vous offrez à votre société
     une téléphonie qui vous permettra de mieux travailler. C’est un investissement temps nécessaire
     pour intégrer la téléphonie sereinement dans votre organisation.
    </p>
    <br />

    <p class="text-justify text-lg leading-6 text-gray-600">
     👉 Votre contact commercial est là pour vous accompagner. Prévoyez au moins 60 minutes avec lui
     pour parcourir ce document et le compléter ensemble.
    </p>
    <br />

    <p class="text-justify text-lg leading-6 text-gray-600">
     Une fois cette collecte finalisée, notre service technique prendra contact avec vous pour
     planifier la réunion de lancement de votre chantier. Cette réunion permettra de valider tous
     les éléments envoyés et planifier votre déploiement.
    </p>
    <p class="mt-3 text-right text-lg leading-6 text-gray-600">A très bientôt, L’équipe Encom</p>
    <a
     @click="this.$emit('closeIntro', false)"
     class="mt-8 w-auto inline-flex items-center cursor-pointer justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-50 body__button"
    >
     Commencer la Collecte
    </a>
   </div>
  </div>
 </div>
</template>

<script>
import ProgressBar from "../ProgressBar.vue";
import { UserCircleIcon } from "@heroicons/vue/solid";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
 props: ["collectType"],
 components: {},
 data() {
  return {};
 },
 methods: {},
 mounted() {},
 computed: {},
};
</script>

<style></style>
