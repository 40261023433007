<template>
 <!-- <div
    class="bg-gray-50 border border-gray-200 sm:items-center search_heading sticky top-0 flex flex-wrap justify-between"
  >
    <div class="col-start-2 col-end-3">
      <div class="mt-3 sm:mt-0 sm:ml-0 flex-1">
        <SearchFilter
          v-model:search="search"
          :inputPlaceholder="$t('search')"
        />
      </div>
    </div>
  </div> -->
 <div class="flex flex-col" v-if="data">
  <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
   <div class="py-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
    <div class="overflow-hidden">
     <!-- <TablePagination
            :tableData="filteredData()"
            @sort="sort($event)"
            @activeBanner="(activeBanner = true), getData(), (key = !key)"
            @successMsg="successMsg = $event"
            @success="isSuccess = $event"
          /> -->
     <CollectsList
      :data="this.data"
      @refresh-data="getData()"
      @activeBanner="$emit('activeBanner')"
      @successMsg="$emit('successMsg', $event)"
      @success="$emit('success', $event)"
     />
    </div>
   </div>
  </div>
 </div>
 <div v-else class="flex justify-center pt-20">
  <div class="text-center">
   <svg
    class="mx-auto h-12 w-12 text-gray-400"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    aria-hidden="true"
   >
    <path
     vector-effect="non-scaling-stroke"
     stroke-linecap="round"
     stroke-linejoin="round"
     stroke-width="2"
     d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
    />
   </svg>
   <h3 class="mt-2 text-lg font-medium text-gray-900">
    {{ $t("warning.noDataFoundFilesTitle") }}
   </h3>
   <p class="mt-1 text-base text-gray-500">
    {{ $t("warning.noDataFoundFilesDescription") }}
   </p>
  </div>
 </div>
</template>

<script>
import SuccessBanner from "../../components/SuccessBanner.vue";
import SearchFilter from "../../components/SearchFilter.vue";
import CollectsList from "../../components/Collect/CollectsList.vue";
import { mapGetters } from "vuex";
import axios from "axios";

const account =
 localStorage.getItem("account") === ""
  ? this.$store.state.user.activeAccount
  : localStorage.getItem("account");

export default {
 props: ["fileId"],
 components: {
  CollectsList,
  SearchFilter,
  SuccessBanner,
 },
 data() {
  return {
   activeBanner: false,
   isSuccess: false,
   key: false,
   successMsg: "",
   search: "",
   data: [],
   currentSort: "id",
   currentSortDir: "asc",
   account,
  };
 },
 methods: {
  async getData() {
   try {
    this.$emit("setLoading", true);

    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/customerFiles/${this.fileId}?customerAccount=${
      this.account
     }`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    this.$emit("setLoading", false);

    if (res.data && res.data.information_collections) {
     this.data = res.data;
    }
   } catch (error) {
    this.$emit("setLoading", false);

    this.errorHandling(error);
   }
  },
  sort(s) {
   if (s === this.currentSort) {
    this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
   }
   this.currentSort = s;
  },
  sorted() {
   return this.data.sort((a, b) => {
    let toBeSorted = this.currentSort;
    let modifier = 1;
    if (this.currentSortDir === "desc") modifier = -1;
    if (a[toBeSorted] < b[toBeSorted]) return -1 * modifier;
    if (a[toBeSorted] > b[toBeSorted]) return 1 * modifier;
    return 0;
   });
  },
  filteredData() {
   return this.sorted().filter((res) => {
    return res.content.toLowerCase().includes(this.search.toLowerCase());
   });
  },
  getYear(date) {
   const dateCreated = new Date(date);
   const formatedDate = dateCreated.getFullYear();

   return String(formatedDate);
  },
  getMonth(date) {
   const dateCreated = new Date(date);
   const formatedDate = ("0" + (dateCreated.getMonth() + 1)).slice(-2);
   return String(formatedDate);
  },
 },
 mounted() {
  this.getData();
 },
 computed: {
  ...mapGetters(["language"]),
 },
};
</script>

<style>
.invoice_table {
 overflow-x: hidden;
 overflow-y: hidden;
}
</style>
